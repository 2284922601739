import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { plainToClass } from 'class-transformer';
import HotelModel from '@/modules/hotels/models/hotel.model';
import type ApiService from '@/modules/common/services/api.service';
import type CompsetsApiService from '../compsets/compsets-api.service';

@injectable()
export default class HotelsApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;
    @inject(KEY.CompsetsApiService) private compsetsApiService!: CompsetsApiService;

    async getHotels(fornovaId: number): Promise<HotelModel[] | null> {
        const data = await this.compsetsApiService.getCompsetsReq(fornovaId);

        if (!data) {
            return null;
        }

        const hotelsDocument = plainToClass(HotelModel, <HotelModel[]> data.hotels, { excludeExtraneousValues: true });

        return hotelsDocument;
    }

    async getMyHotels(): Promise<HotelModel[] | null> {
        const { data } = await this.apiService.get('users/fornova-ids');

        if (!data) {
            return null;
        }

        return plainToClass(HotelModel, <HotelModel[]> data, { excludeExtraneousValues: true });
    }

    async getHotelsById(hotelIds: number[]): Promise<HotelModel[] | null> {
        const { data } = await this.apiService.post('supported/hotel-names', {
            fornovaIds: hotelIds,
        });

        if (!data) {
            return null;
        }

        return plainToClass(HotelModel, <HotelModel[]> data, { excludeExtraneousValues: true });
    }
}
