import { inject, injectable } from '@/inversify';
import { plainToClass } from 'class-transformer';
import { KEY } from '@/inversify.keys';
import { routerData } from '@/router/routerData';
import type HelperService from '@/modules/common/services/helper.service';
import type RatesService from '@/modules/rates/rates.service';

import { RatesDocToTrendsModel, PlainDocument } from './models/rates-doc-to-trends.model';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '../room-types/room-types.service';
import type ProvidersService from '../providers/providers.service';
import RatesCommonService, { RatesCommonServiceS } from '../common/modules/rates/rates-common.service';
import { calculateDiff } from '../common/utils/calculate-diff';
import RatesPriceHistoryApiService, { RatesPriceHistoryApiServiceS } from './rates-price-history-api.service';
import { PriceHistoryToTrendsModel } from './models/price-history-to-trends.model';
import { PRICE_SHOWN } from '../rates/constants';
import type {
    CompsetCompareData,
    RateTrendsService,
    TrendsWithRatesDocument,
    TrendFilters,
    TrendsDocument,
    TrendsStatistics,
    TrendsWithIntraday,
} from './types';
import type RatesDocumentModel from '../rates/models/rates-document.model';
import type RatesDocumentItemModel from '../rates/models/rates-document-item.model';
import type { HotelRooms } from '../common/interfaces';
import type Day from '../common/types/day.type';
import type CompsetsService from '../compsets/compsets.service';
import type HotelRateTrendsStore from './store/hotel-rate-trends.store';
import type StoreFacade from '../common/services/store-facade';
import type DocumentFiltersService from '../document-filters/document-filters.service';
import type HotelsService from '../hotels/hotels.service';

@injectable()
export default class HotelRateTrendsService implements RateTrendsService, TrendsWithRatesDocument, TrendsWithIntraday {
    /** New version of price history service. This one only for rates hotel level popup */

    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.RatesService) private ratesService!: RatesService;
    @inject(KEY.CompsetsService) private compsetService!: CompsetsService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(RatesPriceHistoryApiServiceS) private ratesPriceHistoryApiService!: RatesPriceHistoryApiService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;

    private readonly storeState: HotelRateTrendsStore = this.storeFacade.getState('HotelRateTrendsStore');

    constructor() {
        // Dependencies to reset whole document
        this.storeFacade.watch(
            () => [
                this.ratesDocument,
                this.date,
            ],
            async (n, o) => {
                if (JSON.stringify(n) === JSON.stringify(o) || !this.date) {
                    return;
                }

                // For all rate documents on hotel page we use same ratesService, so we need to skip reset for 'all' and 'cheapest' providers
                if (this.ratesDocument?.providerName === 'all'
                    || this.ratesDocument?.providerName === 'cheapest'
                    || routerData.router.currentRoute.name?.includes('analysis')
                ) {
                    return;
                }

                // Avoid extra requests if document not loaded yet.
                if (this.ratesService.isLoading || this.compsetService.isLoading) {
                    return;
                }

                // At the moment rates document is set into trends, intraday have to be loaded.
                if (this.isIntraday) {
                    this.ratesService.storeState.intraday.loading.start();
                    await this.ratesService.loadIntradayData(this.date?.getDate() as Day);
                    this.ratesService.storeState.intraday.loading.finish();
                }

                this.storeState.trendsLoading.reset();
            },
        );
    }

    get compset() {
        return this.compsetService.currentCompset;
    }

    get ratesDocument() {
        return this.ratesService.dataWithoutLoading as RatesDocumentModel | null;
    }

    get trendsDocument() {
        this.helperService.dynamicLoading(this.storeState.trendsLoading, this.loadData.bind(this));
        return this.storeState.trendsDocument;
    }

    get filters() {
        const { los, pos, competitors } = this.documentFiltersService.settings;
        const {
            provider, roomTypeId, mealTypeId, numberOfGuests, priceType,
        } = this.ratesService.settings;
        const mealType = this.mealTypesService.getMealType(mealTypeId);
        const roomType = this.roomTypesService.getRoomType(roomTypeId);
        const providerLabel = provider ? this.providersService.getProviderLabel(provider) : '';

        if (los === null
            || !pos
            || !competitors
            || !providerLabel
            || !roomType?.displayName
            || !mealType?.displayName
            || numberOfGuests === null
            || !priceType
            || !this.compset
        ) {
            return null;
        }

        return {
            los,
            pos,
            entities: competitors.map(String).concat([String(this.compset.ownerHotelId)]),
            provider: providerLabel,
            roomType: roomType.displayName,
            numberOfGuests,
            priceType,
            mealType: mealType.displayName,
            compsetName: this.compset.name,
            compsetType: this.compset.type,
        } as TrendFilters;
    }

    get date() {
        return this.storeState.date;
    }

    set date(d: Date | null) {
        this.storeState.date = d;
    }

    get localPriceShown() {
        return this.storeState.localPriceShown;
    }

    set localPriceShown(p: PRICE_SHOWN) {
        this.storeState.localPriceShown = p;
    }

    get trendsLoading() {
        return this.storeState.trendsLoading;
    }

    get ratesLoading() {
        return this.ratesService.loading;
    }

    get statistics() {
        const trends = (this.trendsDocument?.trendData || []) as TrendsDocument['trendData'];
        const stats = trends.map(dayTrend => {
            if (!dayTrend || !this.compset || !trends.length) {
                return null;
            }

            const { competitors } = this.documentFiltersService.settings;
            const compRooms = competitors.reduce((acc, hotelId) => {
                const hotelData = dayTrend.data[hotelId];

                if (!hotelData || !hotelData.rooms) {
                    return acc;
                }

                return {
                    ...acc,
                    [hotelId]: hotelData.rooms[0] || null,
                };
            }, {} as HotelRooms);

            const mainRooms = dayTrend.data[String(this.compset.ownerHotelId)]?.rooms;
            const mainRate = (mainRooms && mainRooms[0]?.price)
                ? mainRooms[0].price[`${this.localPriceShown}Price` as keyof RatesDocumentItemModel['price']]
                : null;

            const compRate = this.ratesCommonService.getCompsetPrice(compRooms, this.compset.type, this.localPriceShown);
            const diff = (compRate && mainRate) ? calculateDiff(mainRate, compRate, 0) : null;
            const assessment = diff !== null
                ? this.ratesCommonService.getCardAssessment(diff / 100, this.compset)
                : null;

            const compset = {
                compRates: [compRate],
                mainRate,
                diff,
                type: this.compset.type,
                assessment,
            } as CompsetCompareData;

            return {
                compset,
                occupancy: dayTrend.occupancy,
                demand: dayTrend.demand,
                currency: this.trendsDocument!.currency,
            } as TrendsStatistics;
        });

        return stats;
    }

    get isIntraday() {
        const { competitors } = this.documentFiltersService;
        const day = this.date?.getDate() as Day;
        return competitors.some(c => this.ratesService.isIntraday(day, c)) || !!this.ratesService.isIntraday(day);
    }

    init(day: Day) {
        this.localPriceShown = this.documentFiltersService.priceShown;
        this.update(day);
    }

    update(day: Day) {
        const { year, month } = this.documentFiltersService;
        this.date = new Date(year, month, day);
    }

    /** Loads trends data and use ratesDocument as its 1st item */
    private async loadData() {
        if (!this.date) {
            return false;
        }

        // Empty doc after the request - out of range
        if (!this.ratesDocument?.id) {
            return true;
        }

        this.storeState.trendsDocument = this.generateRatesDocumentIntoTrends();

        if (!this.trendsDocument) {
            throw new Error('Rates document can`t be parsed.');
        }

        const lastUpdate = this.ratesDocument.checkinDates ? this.ratesDocument.checkinDates[this.date.getDate()].updateDate : null;

        // At this moment rates loading is completed, but if no data for the rates document, trends can't be requested.
        if (!lastUpdate) {
            return true;
        }

        const day = this.date.getDate();
        const docId = this.ratesDocument.id;
        const { currency } = this.ratesDocument;

        const priceHistoryData = await this.ratesPriceHistoryApiService
            .getRatesPriceHistoryByDay(day as Day, docId, this.ratesService.settings, currency || null);

        const historyTrendsDocument = plainToClass(
            PriceHistoryToTrendsModel,
            { ...priceHistoryData, lastUpdate },
            { excludeExtraneousValues: true },
        ) as TrendsDocument;

        // Set price history data to trends document, except 0 element.
        // 0 element is a group doc.
        this.storeState.trendsDocument = {
            ...this.trendsDocument,
            trendData: [
                this.trendsDocument.trendData[0],
                ...historyTrendsDocument.trendData.slice(1, historyTrendsDocument.trendData.length),
            ],
        };

        return true;
    }

    /** Generates trends document with ratesDocument as `0` element. */
    private generateRatesDocumentIntoTrends() {
        const intradayRooms = this.isIntraday ? [this.ratesService.intradayHotelRooms(this.date!.getDate() as Day)] : null;
        const { competitors, ownerHotelId } = this.compset!;
        const names = [...competitors, ownerHotelId].reduce((acc, id) => ({
            ...acc,
            [id]: this.hotelsService.hotelNames[id],
        }), {} as Record<string, string>);

        const trendsDocument = plainToClass(
            RatesDocToTrendsModel,
            {
                ...this.ratesDocument!,
                extraRooms: intradayRooms,
                date: this.date,
                names,
            } as PlainDocument,
            { excludeExtraneousValues: true },
        ) as TrendsDocument;

        return trendsDocument;
    }
}
