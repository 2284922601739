const services = [
    'StoreFacade',
    'Store',
    'ApiService',
    'HelperService',
    'ExceptionHandler',
    'ConfigService',
    'CacheService',
    'DocumentFiltersService',
    'UserService',
    'NotificationService',
    'NotificationApiService',
    'AlertsService',
    'ProvidersService',

    'RateValueScoreApiService',
    'RateValueScoreSerivce',
    'RVSCalendarService',
    'RVSOvertimeService',
    'RVSScoreService',
    'RVSDetailsService',
    'RVSWeightSettingsService',

    'SettingsApiService',
    'SettingsGeneralService',
    'SettingsHotelService',

    'ClusterRatesService',
    'ClusterService',
    'ClusterMarketsService',
    'ClusterDiLiteService',
    'ClusterPromotionsService',
    'ClusterRvsService',
    'ClusterCompsetsService',

    'InsightFiltersService',
    'MarketsHistoryService',
    'RatesPriceHistoryCommonService',
    'RatesFiltersService',
    'RatesService',
    'MarketsFiltersService',
    'MarketsService',
    'RankingService',
    'DiLiteAllChannelsService',
    'PromotionsService',
    'InsightsService',
    'DeepCompsetAnalysisService',
    'HomeService',
    'DiLiteMarketService',
    'ClusterRvsService',
    'HotelsService',
    'CompsetsService',
    'RankingHistoryService',
    'JiraService',

    'CompsetsApiService',
    'HotelsApiService',

    'HotelRateTrendsService',
    'HotelAllChannelsTrendsService',
    'HotelCheapestTrendsService',
    'HotelAnalysisTrendsService',
    'ClusterRateTrendsService',
    'ClusterAllChannelsTrendsService',
] as const;

const guards = [
    'AdminGuard',
    'ReadonlyGuard',
] as const;

const injectables = [
    ...services,
    ...guards,
];

export const KEY = Object.fromEntries(injectables.map(key => [key, Symbol.for(key)])) as Record<typeof injectables[number], symbol>;
