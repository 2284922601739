import { $enum } from 'ts-enum-util';

import { inject } from '@/inversify.inject';
import { injectable } from '@/inversify.injectable';
import { KEY } from '@/inversify.keys';

import UserStore from '../user/store/user.store';
import { HotelNotifications } from './constants';
import SettingsApiService from './settings-api.service';
import SettingsStore from './settings.store';
import { HotelAlertsAndNotifications } from '../user/models/user-hotel-alerts.model';
import { CompsetReportsSettings } from './models/settings.model';
import type CompsetsService from '../compsets/compsets.service';
import type HelperService from '../common/services/helper.service';
import type StoreFacade from '../common/services/store-facade';
import type HotelsService from '../hotels/hotels.service';

interface SettingsHotelServicePublicInterface {
    /**
     * Contains a list of compset IDs of the current
     * hotel that are used for generating HTML-reports
     *
     * Use updateCompsetsForReports to update this list
     */
    emailHtmlReportSettings: CompsetReportsSettings[] | null;
}

@injectable()
export class SettingsHotelService implements SettingsHotelServicePublicInterface {
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.HotelsService)private hotelsService!: HotelsService;
    @inject(KEY.SettingsApiService) private settingsApiService!: SettingsApiService;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;

    private readonly userStoreState: UserStore = this.storeFacade.getState('UserStore');
    private readonly storeState: SettingsStore = this.storeFacade.getState('SettingsStore');

    get alertsAndNotifications() {
        this.helperService.dynamicLoading(this.storeState.hotelAlertsAndNotifications.loading, this.loadHotelSettings.bind(this));
        return this.storeState.hotelAlertsAndNotifications.hotels;
    }

    get isAlertsAndNotificationsLoading() {
        return this.storeState.hotelAlertsAndNotifications.loading.isLoading();
    }

    get emailHtmlReportSettings() {
        const { settings, currentHotelId } = this.userStoreState.user || {};
        if (!settings) return null;

        const { emailHtmlReportSettings } = settings;
        if (!emailHtmlReportSettings) return null;

        return emailHtmlReportSettings[currentHotelId!] || null;
    }

    get scoreComparedTo() {
        return this.storeState.scoreComparedTo;
    }

    set scoreComparedTo(value: number) {
        this.storeState.scoreComparedTo = value;
    }

    private async loadHotelSettings() {
        const { user } = this.userStoreState;

        if (!user) {
            return false;
        }

        await this.compsetsService.loading.whenLoadingFinished();

        const { currentHotelId } = user;
        if (currentHotelId === null) {
            return false;
        }

        const res = await this.settingsApiService.getHotelSettings(currentHotelId);

        if (res.hotels === null) {
            return false;
        }

        $enum(HotelNotifications).forEach(value => {
            if (res.hotels![currentHotelId][value]) return;

            res.hotels![currentHotelId][value] = {};
        });

        this.storeState.hotelAlertsAndNotifications = {
            ...this.storeState.hotelAlertsAndNotifications,
            hotels: res.hotels,
        };

        return true;
    }

    saveAlertsAndNotifications(fornovaId: number, newSettings: HotelAlertsAndNotifications[0]) {
        return this.settingsApiService.updateHotelSettings({ [fornovaId]: newSettings });
    }

    async saveCompsetReportSettings(newSettings: CompsetReportsSettings[]) {
        const { user } = this.userStoreState;
        if (!user) return;

        if (!user.settings.emailHtmlReportSettings) {
            user.settings.emailHtmlReportSettings = {};
        }

        await this.settingsApiService.updateSettings({
            emailHtmlReportSettings: {
                [user.currentHotelId!]: newSettings,
            },
        });
    }
}
