import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import type StoreFacade from '@/modules/common/services/store-facade';
import type HelperService from '@/modules/common/services/helper.service';
import ReportsApiService, { ReportsApiServiceS } from './reports-api.service';
import ReportsStore from './store/reports.store';
import { IForm } from './interfaces';
import { DATA_TYPE } from './constants';
import ScheduledItemModel from './models/scheduled-item.model';

export const ReportsServiceS = Symbol.for('ReportsServiceS');
@injectable()
export default class ReportsService implements Stateable {
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(ReportsApiServiceS) private reportsApiService!: ReportsApiService;
    @inject(KEY.HelperService) private helperService!: HelperService;

    readonly storeState: ReportsStore = this.storeFacade.getState('ReportsStore');

    get loading() {
        return this.storeState.loading;
    }

    async loadData(dataType: DATA_TYPE): Promise<boolean> {
        const items = await this.reportsApiService.getExcelReports(dataType);
        if (items) {
            this.storeState.items = items;
        }

        return true;
    }

    getReports(dataType: DATA_TYPE) {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this, dataType));
        return this.storeState.items;
    }

    resetReports() {
        this.storeState.items = null;
        this.storeState.loading.reset();
    }

    async updateReport(reportId: string, form: IForm): Promise<boolean> {
        this.loading.start();
        let updatedReport = null;

        try {
            updatedReport = await this.reportsApiService.putExcelReport(reportId, form);
        } catch (e) {
            throw e;
        } finally {
            this.loading.finish();
            if (updatedReport) {
                this.resetReports();
            }
        }

        return true;
    }

    async addReport(form: IForm): Promise<boolean> {
        this.loading.start();
        let newReport = null;

        try {
            newReport = await this.reportsApiService.postExcelReport(form);
        } catch (e) {
            throw e;
        } finally {
            this.loading.finish();
            if (newReport) {
                this.resetReports();
            }
        }

        return true;
    }

    getReport(id: string) {
        return this.storeState.items
            ? this.storeState.items.find(item => item.id === id)
            : null;
    }

    addReportToStore(report: ScheduledItemModel) {
        if (this.storeState.items) {
            this.storeState.items.push(report);
            return;
        }

        this.storeState.items = [report];
        this.storeState.loading.start();
        this.storeState.loading.finish();
    }

    async deleteReport(reportId: string) {
        this.loading.start();

        try {
            await this.reportsApiService.deleteExcelReport(reportId);
        } catch (e) {
            throw e;
        } finally {
            this.loading.finish();
            this.storeState.loading.reset();
        }
    }

    async unsubscribeByToken(token: string) {
        const res = await this.reportsApiService.unsubscribeEmail(token);
        return res;
    }

    downloadReportBlobById(reportId: number) {
        return this.reportsApiService.downloadReportBlobById(reportId);
    }
}
