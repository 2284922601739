import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';

import ScanDisabledProviders from '@/modules/common/modules/rates/constants/scan-disabled-providers.enum';
import ProvidersStore from '@/modules/providers/store/providers.store';
import ProvidersApiService, { ProvidersApiServiceS } from '@/modules/providers/providers-api.service';
import { i18n } from '@/modules/translations/translations.service';
import type HelperService from '@/modules/common/services/helper.service';
import type StoreFacade from '@/modules/common/services/store-facade';
import type UserService from '@/modules/user/user.service';
import type CompsetsService from '../compsets/compsets.service';
import type { ProvidersServicePublicInterface } from './types';

@injectable()
export default class ProvidersService implements ProvidersServicePublicInterface {
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(ProvidersApiServiceS) private providersApiService!: ProvidersApiService;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;

    private readonly storeState: ProvidersStore = this.storeFacade.getState('ProvidersStore');

    get allProvidersCollection() {
        this.helperService.dynamicLoading(this.storeState.allProvidersCollectionLoading, this.loadAllProvidersCollection.bind(this));
        return this.storeState.allProvidersCollection;
    }

    get allProvidersCollectionLoading() {
        return this.storeState.allProvidersCollectionLoading;
    }

    get userProviders() {
        this.helperService.dynamicLoading(this.storeState.userProvidersLoading, this.loadUserProviders.bind(this));

        return {
            rateProviders: this.storeState.userRateProviders,
            marketProviders: this.storeState.userMarketProviders,
            promotionProviders: this.storeState.userPromotionProviders,
        };
    }

    get userProvidersLoading() {
        return this.storeState.userProvidersLoading;
    }

    get currentHotelProviders() {
        return {
            rateProviders: this.compsetsService.currentCompset?.rateProviders || [],
            marketProviders: this.compsetsService.currentCompset?.marketProviders || [],
        };
    }

    get currentHotelProvidersLoading() {
        return this.compsetsService.loading;
    }

    private async loadAllProvidersCollection(): Promise<boolean> {
        this.storeState.allProvidersCollection = await this.providersApiService.getProviders();
        return true;
    }

    private async loadUserProviders() {
        const { levelName, chainId } = this.userService;

        if (!chainId) {
            return true;
        }

        const dictionary = await this.providersApiService.getChainProviders(chainId, levelName);

        if (!dictionary) {
            return true;
        }

        this.storeState.userMarketProviders = dictionary.markets;
        this.storeState.userRateProviders = dictionary.rates;
        const promotionAllowedProviders = ['booking', 'expedia', 'traveloka', 'ctrip', 'ctrip_mobile_web', 'ctrip_api'];
        this.storeState.userPromotionProviders = dictionary.markets.filter(p => promotionAllowedProviders.includes(p));

        return true;
    }

    getDIName(provider: string) {
        if (!this.allProvidersCollection) {
            return null;
        }

        const find = this.allProvidersCollection.find((element: any) => (element.name === provider));
        if (!find) {
            return null;
        }

        return find.diName;
    }

    getMaxScore(provider: string) {
        if (!this.allProvidersCollection) {
            return null;
        }

        const find = this.allProvidersCollection.find((element: any) => (element.name === provider));
        if (!find) {
            return null;
        }

        return find.maxScore;
    }

    getProviderLabel(providerName: string) {
        if (!this.allProvidersCollection) {
            return providerName;
        }

        const provider = this.allProvidersCollection
            .find(({ name }) => name === providerName);

        if (!provider) {
            return '';
        }

        const translateableValues = ['all', 'cheapest'];
        const value = provider.label || provider.name || '';

        return translateableValues.includes(providerName)
            ? i18n.tc(value)
            : value;
    }

    toItemsList(providerList: string[], ignoreList: string[] = []) {
        return providerList
            .filter(provider => !ignoreList.includes(provider))
            .map(provider => ({
                value: provider,
                name: this.getProviderLabel(provider),
                disabled: false,
            }));
    }

    isScanDisabledProvider(provider: string | null) {
        const foundProviders = Object
            .values(ScanDisabledProviders)
            .some(value => (provider
                ? provider.includes(value)
                : false));

        return foundProviders;
    }
}
