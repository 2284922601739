import _ from 'lodash';
import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';

import type ProvidersService from '@/modules/providers/providers.service';
import InsightsApiService, { InsightsApiServiceS } from './insights-api.service';
import type HelperService from '../common/services/helper.service';
import type UserService from '../user/user.service';
import type CompsetsService from '../compsets/compsets.service';
import type StoreFacade from '../common/services/store-facade';

import InsightsStore from './insights.store';
import InsightGroupModel from './models/insight-group.model';
import { InsightType } from './constants';
import InsightDayRateModel from './models/insight-day-rate.model';
import InsightDayRateTrendModel from './models/insight-day-rate-trend.model';
import InsightDayMarketModel from './models/insight-day-market.model';
import InsightDayDeviceModel from './models/insight-day-device.model';
import { Insight, RoomsInsight } from './types';
import type InsightFiltersService from '../document-filters/insight-filters.service';

const INSIGHTS_LIMIT = 36;

interface InsightsPublicInterface {
    /** Getter with dynamic loading to get insights by filters from the store. */
    groups: InsightGroupModel<Insight>[];

    /** Triggers lazy loading */
    loadMoreInsights: () => void

    /** Resets day data loading set day data to null and if insight id provided, it is set as new insightId */
    resetDayData: (insightId?: string) => void

    /** Returns current insight id from the store */
    dayInsightId: string | null;

    /** Getter with dynamic loading to get specific insight from the group */
    dayData: InsightDayRateModel | InsightDayMarketModel | InsightDayDeviceModel | InsightDayRateTrendModel | null;

    /** Getter with dynamic loading to get specific insights group */
    dayGroup: InsightGroupModel<Insight | RoomsInsight> | null;

    /** Is insight filters loading. */
    isTypesLoading: boolean;

    /** Is insight document loading. */
    isInsightLoading: boolean;

    /** Is insight lazy loading working. */
    isInsightLoadingMore: boolean;

    /** Is insight day popup loading */
    isDayDataLoading: boolean;

    /** Set group viewed */
    setGroupViewed: (groupId: string) => Promise<boolean>;

    /** Set group unviewed */
    setGroupUnviewed: (groupId: string) => Promise<boolean>;

    /** Permanently delete group */
    deleteGroup: (groupId: string) => Promise<boolean>;
}

// TODO split popup functionality to separate service
export const InsightsServiceS = Symbol.for('InsightsServiceS');
@injectable()
export default class InsightsService implements InsightsPublicInterface {
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(InsightsApiServiceS) private insightsApiService!: InsightsApiService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;
    @inject(KEY.ProvidersService) providersService!: ProvidersService;
    @inject(KEY.InsightFiltersService) private insightFiltersService!: InsightFiltersService;

    private readonly storeState: InsightsStore = this.storeFacade.getState('InsightsStore');

    constructor() {
        // Lazy loading
        this.storeFacade.watch(
            () => this.storeState.isLoadingMoreInsights,
            () => {
                if (!this.storeState.isLoadingMoreInsights) {
                    return;
                }

                this.storeState.insightsLoading.reset();
            },
        );

        // First loading, filter change, view as change
        this.storeFacade.watch(
            () => [this.compsetsService.isLoading, this.insightFiltersService.settings, this.userService.currentHotelId],
            (n, o) => {
                // Sometimes watcher hits even if old and new value are the same
                if (_.isEqual(o, n)) {
                    return;
                }

                if (this.compsetsService.isLoading) {
                    this.storeState.groups = [];
                    return;
                }

                this.storeState.count = 0;
                this.storeState.skip = 0;

                const { providers } = this.insightFiltersService.settings;
                const { insightTypes } = this.insightFiltersService.settings;

                this.storeState.groups = [];

                if (insightTypes?.length || providers?.length) {
                    this.storeState.insightsLoading.reset();
                }
            },
        );
    }

    get count() {
        return this.storeState.count;
    }

    get skip() {
        return this.storeState.skip;
    }

    get targetedInsights() {
        return this.userService.user.settings.defaultFilters.targetedInsights;
    }

    get groups() {
        if (!this.userService.isUserLoaded
            || (this.storeState.insightsLoading.isInitialized && this.isInsightLoading)
        ) {
            return this.storeState.groups;
        }

        this.helperService.dynamicLoading(this.storeState.insightsLoading, this.loadInsights.bind(this));

        return this.storeState.groups;
    }

    async loadInsights() {
        const { insightTypes, dateRange, providers } = this.insightFiltersService.settings;

        if (!providers?.length
            || !insightTypes?.length
            || !this.userService.currentHotelId
            || this.compsetsService.isLoading
        ) {
            return false;
        }

        const fornovaId = this.userService.currentHotelId;
        const settings = {
            fornovaId,
            providers,
            insightTypes: insightTypes.map(t => t.value),
            dateRange,
        };

        const pagination = { skip: this.storeState.skip, limit: INSIGHTS_LIMIT };
        const res = await this.insightsApiService.getInsightGroups(settings, pagination);

        this.storeState.groups = [...this.storeState.groups, ...res.data];
        this.storeState.isLoadingMoreInsights = false;

        this.storeState.count = res.count;
        this.storeState.skip += INSIGHTS_LIMIT;

        return true;
    }

    loadMoreInsights() {
        this.storeState.isLoadingMoreInsights = true;
    }

    private async loadDayPopupData() {
        const params = window.location.pathname.split('/');
        const groupId = params[params.length - 1];

        const { dayDataGroupId: lastGroupId } = this.storeState;

        if (lastGroupId !== groupId || !this.storeState.dayGroup) {
            this.storeState.dayGroup = null;
            const group = await this.insightsApiService.getInsightGroup(groupId);

            // Keep only insight with provider from filter
            if (this.insightFiltersService.settings.providers?.length) {
                const insIds = Object.keys(group.insights);
                const providerValues = this.insightFiltersService.settings.providers;
                insIds.forEach(id => {
                    if (!providerValues.includes(group.insights[id].provider)) {
                        delete group.insights[id];
                    }
                });
            }

            this.storeState.dayGroup = group;
        }

        if (!this.storeState.dayGroup) {
            return false;
        }

        // If no insight id in the store or insight id is from another group then take first insight id from the current group
        if (!this.storeState.dayDataInsightId || !Object.keys(this.storeState.dayGroup.insights).includes(this.storeState.dayDataInsightId)) {
            [this.storeState.dayDataInsightId] = Object.keys(this.storeState.dayGroup.insights);
        }

        const { type, date } = this.storeState.dayGroup;

        // No need to load insights for rooms and market leader. All info is in group.
        if (type === InsightType.ROOMS || type === InsightType.MARKET_LEADER) {
            return true;
        }

        const { dayDataInsightId: insightId } = this.storeState;
        const payload = { groupId, date, insightId };

        switch (type) {
            case InsightType.RATE:
            case InsightType.LOS:
                this.storeState.dayData = await this.insightsApiService.getDayInsights(InsightDayRateModel, payload);
                break;
            case InsightType.VISIBILITY:
            case InsightType.PROMOTION:
                this.storeState.dayData = await this.insightsApiService.getDayInsights(InsightDayMarketModel, payload);
                break;
            case InsightType.DEVICE:
                this.storeState.dayData = await this.insightsApiService.getDayInsights(InsightDayDeviceModel, payload);
                break;
            case InsightType.RATE_TRENDS:
                this.storeState.dayData = await this.insightsApiService.getDayInsights(InsightDayRateTrendModel, payload);
                break;
            default:
                throw new Error(`Day data functionality not implemented for ${type} insight type.`);
        }

        return true;
    }

    resetDayData(insightId?: string) {
        this.storeState.dayDataLoading.reset();
        this.storeState.dayData = null;
        if (insightId) {
            this.storeState.dayDataInsightId = insightId;
        }
    }

    get dayInsightId() {
        return this.storeState.dayDataInsightId;
    }

    get dayData() {
        this.helperService.dynamicLoading(this.storeState.dayDataLoading, this.loadDayPopupData.bind(this));
        return this.storeState.dayData;
    }

    get dayGroup() {
        this.helperService.dynamicLoading(this.storeState.dayDataLoading, this.loadDayPopupData.bind(this));
        return this.storeState.dayGroup;
    }

    get isTypesLoading() {
        return !this.insightFiltersService.loading.insightTypes.isInitialized
            || this.insightFiltersService.loading.insightTypes.isLoading();
    }

    get isInsightLoading() {
        return !this.storeState.insightsLoading.isInitialized
            || this.storeState.insightsLoading.isLoading()
            || this.isTypesLoading
            || this.compsetsService.isLoading;
    }

    get isInsightInitLoading() {
        return this.isInsightLoading && !this.isInsightLoadingMore;
    }

    get isInsightLoadingMore() {
        return this.isInsightLoading && this.storeState.isLoadingMoreInsights;
    }

    get isDayDataLoading() {
        return !this.storeState.dayDataLoading.isInitialized || this.storeState.dayDataLoading.isLoading();
    }

    get insightsLoading() {
        return this.storeState.insightsLoading;
    }

    async setGroupViewed(groupId: string) {
        const res = await this.insightsApiService.setGroupViewed(groupId);
        const index = this.storeState.groups.findIndex(grp => grp.groupId === groupId);
        if (index === -1) {
            return res;
        }
        this.storeState.groups[index].read = true;
        return res;
    }

    async setGroupUnviewed(groupId: string) {
        const res = await this.insightsApiService.setGroupUnviewed(groupId);
        const index = this.storeState.groups.findIndex(grp => grp.groupId === groupId);
        if (index === -1) {
            return res;
        }
        this.storeState.groups[index].read = false;
        return res;
    }

    async deleteGroup(groupId: string) {
        const res = await this.insightsApiService.deleteGroup(groupId);
        const index = this.storeState.groups.findIndex(grp => grp.groupId === groupId);
        if (index === -1) {
            return res;
        }
        this.storeState.groups[index].deleted = true;
        this.storeState.groups[index].read = true;
        this.storeState.groups = [...this.storeState.groups];
        return res;
    }
}
