import { Route } from 'vue-router';
import use from '@/router/use';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import { container } from '@/inversify.container';
import PaginationMiddleware, { PaginationMiddlewareS } from '@/router/middlewares/pagination.middleware';
import { CommonRoutes } from '../common.routes';

function RatesScreenshot(prefix: string) {
    return {
        name: `${prefix}.screenshot`,
        path: 'screenshot',
        props: (route: Route) => ({
            url: route.query.url,
            rectangleUniqueness: route.query.rectangleUniqueness,
        }),
        meta: { modal: true },
        component: () => import('@/modules/rates/pages/rates-screenshot.modal.vue'),
    };
}

export function RatesClusterHistory(prefix: string) {
    return {
        name: `${prefix}.day-rate`,
        path: 'day-rate/:day/:hotelId/:compsetId',
        props: (route: Route) => ({ day: parseInt(route.params.day, 10) }),
        component: () => import('@/modules/price-history/pages/cluster-rates-day.modal.vue'),
        meta: { modal: true },
        children: [
            RatesScreenshot(`${prefix}.day-rate`),
        ],
    };
}

export function ClusterScheduledReports(prefix: string) {
    return {
        name: `${prefix}.cluster-scheduled-reports-popup`,
        path: 'scheduled-reports',
        component: () => import('@/modules/cluster/pages/rates-cluster-report.modal.page.vue'),
        meta: { modal: true },
    };
}

function ClusterDownloadExcel(prefix: string) {
    return {
        name: `${prefix}.download-excel-popup`,
        path: 'download-excel',
        component: () => import('@/modules/cluster/pages/rates-cluster-excel.page.vue'),
        meta: { modal: true },
    };
}

export default function RatesClusterLevelRoutes(basePath: string, prefix: string) {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS), container.get<PaginationMiddleware>(PaginationMiddlewareS)]),
        children: [
            {
                name: `${prefix}.table`,
                path: 'table/',
                component: () => import('@/modules/cluster/pages/rates-cluster-table.page.vue'),
                children: [
                    ClusterDownloadExcel(`${prefix}.table`),
                    ClusterScheduledReports(`${prefix}.table`),
                    RatesClusterHistory(`${prefix}.table`),
                    ...CommonRoutes(`${prefix}.table`),
                ],
            },
            {
                name: prefix,
                path: '',
                component: () => import('@/modules/cluster/pages/rates-cluster-list.page.vue'),
                children: [
                    ClusterDownloadExcel(prefix),
                    ClusterScheduledReports(prefix),
                    RatesClusterHistory(prefix),
                    ...CommonRoutes(prefix),
                ],
            },
        ],
    };
}
