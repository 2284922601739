import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import ProvidersModel from '@/modules/providers/models/providers.model';

@Module
export default class ProvidersStore extends VuexModule {
    allProvidersCollection: ProvidersModel[] | null = null;
    allProvidersCollectionLoading: LoadingModel = new LoadingModel();

    userRateProviders: string[] = [];
    userMarketProviders: string[] = [];
    userPromotionProviders: string[] = [];
    userProvidersLoading: LoadingModel = new LoadingModel();
}
