import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '../common/models/loading.model';
import { HotelAlertsAndNotifications } from '../user/models/user-hotel-alerts.model';
import RVSWeightSettingsModel from './models/rvs-weights-settings.model';

@Module
export default class SettingsStore extends VuexModule {
    rvsWeight = {
        data: null as RVSWeightSettingsModel | null,
        loading: new LoadingModel(),
    };

    hotelAlertsAndNotifications = {
        loading: new LoadingModel(),
        hotels: {} as HotelAlertsAndNotifications,
    };

    scanProperties = {
        loading: new LoadingModel(),
    };

    scoreComparedTo = 1;
}
