import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { plainToClass } from 'class-transformer';
import ProvidersModel from '@/modules/providers/models/providers.model';
import type ApiService from '@/modules/common/services/api.service';

export const ProvidersApiServiceS = Symbol.for('ProvidersApiServiceS');
@injectable()
export default class ProvidersApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;

    async getProviders(): Promise<ProvidersModel[] | null> {
        const { data } = await this.apiService.get('supported/providers');

        if (!data) {
            return [];
        }

        const providersDocument = plainToClass(ProvidersModel, <ProvidersModel[]> data, { excludeExtraneousValues: true });

        return providersDocument;
    }

    async getChainProviders(chainId: string, levelName: string | null) {
        const cluster = levelName ? `/${levelName}` : '';
        const { data } = await this.apiService.get(`supported/chain/provider/${chainId}${cluster}?filterType=clusters`);

        if (!data) {
            return null;
        }

        return {
            rates: data.rate_providers as string[],
            markets: data.market_providers as string[],
        };
    }
}
