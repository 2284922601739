import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import use from '@/router/use';
import { container } from '@/inversify.container';
import { RouteConfig } from 'vue-router';
import PaginationMiddleware, { PaginationMiddlewareS } from '@/router/middlewares/pagination.middleware';
import { CommonRoutes } from '../common.routes';
import { DayMarkets } from '../hotel/markets.routes';

export function ScheduledReports(prefix: string) {
    return {
        name: `${prefix}.scheduled-reports`,
        path: 'scheduled-reports',
        component: () => import('@/modules/cluster/pages/markets-cluster-reports.modal.page.vue'),
        meta: { modal: true },
    };
}

export default function MarketsClusterLevelRoutes(basePath: string, prefix: string): RouteConfig {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS), container.get<PaginationMiddleware>(PaginationMiddlewareS)]),
        children: [
            {
                path: '',
                name: prefix,
                component: () => import('@/modules/cluster/pages/markets-cluster-list.page.vue'),
                children: [
                    ScheduledReports(prefix),
                    DayMarkets(prefix, 'day-markets/:day/:hotelId/:compsetId/:source'),
                    ...CommonRoutes(prefix),
                ],
            },
        ],
    };
}
