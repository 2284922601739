import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type StoreFacade from '../common/services/store-facade';
import InsightFiltersStore from './store/insight-fiters.store';
import { InsightFilterSettingsModel, InsightFilterOptionsModel } from './models/insight-filters.model';
import type HelperService from '../common/services/helper.service';
import InsightsApiService, { InsightsApiServiceS } from '../insights/insights-api.service';
import type CompsetsService from '../compsets/compsets.service';
import type ProvidersService from '../providers/providers.service';
import type UserService from '../user/user.service';

@injectable()
export default class InsightFiltersService {
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(InsightsApiServiceS) private insightsApiService!: InsightsApiService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(KEY.UserService) private userService!: UserService;

    private readonly storeState: InsightFiltersStore = this.storeFacade.getState('InsightFiltersStore');

    constructor() {
        // [TODO] add cluster providers
        this.storeFacade.watch(
            () => [
                this.compsetsService.compsets,
                this.providersService.allProvidersCollection,
            ],
            () => this.setHotelProviders(),
        );
    }

    get settings() {
        return this.storeState.settings;
    }

    get options() {
        return {
            insightTypes: this.insightTypeOptions,
            dateRange: this.dateRangeOptions,
            providers: this.providerOptions,
        };
    }

    get loading() {
        return this.storeState.loading;
    }

    updateSettings(s: Partial<InsightFilterSettingsModel>) {
        this.storeState.settings = {
            ...this.storeState.settings,
            ...s,
        };
    }

    updateOptions(o: Partial<InsightFilterOptionsModel>) {
        this.storeState.options = {
            ...this.storeState.options,
            ...o,
        };
    }

    resetOptions() {
        this.storeState.options = new InsightFilterOptionsModel();
    }

    resetSettings() {
        this.storeState.settings = new InsightFilterSettingsModel();
    }

    private get insightTypeOptions() {
        this.helperService.dynamicLoading(this.storeState.loading.insightTypes, async () => {
            const insightTypes = await this.insightsApiService.getInsightTypes();
            this.updateSettings({ insightTypes: insightTypes.filter(t => t.isActive) });
            this.updateOptions({ insightTypes });
            return true;
        });
        return this.storeState.options.insightTypes;
    }

    private get dateRangeOptions() {
        return this.storeState.options.dateRange;
    }

    private get providerOptions() {
        return this.storeState.options.providers;
    }

    /**
     * Get providers from all compsets
     */
    private setHotelProviders() {
        if (!this.userService.isViewAsHotel) {
            return;
        }

        if (!this.compsetsService.compsets) {
            return;
        }

        const providers = Array.from(this.compsetsService.compsets.reduce((acc, c) => {
            c.rateProviders.forEach(p => acc.add(p));
            c.marketProviders.forEach(p => acc.add(p));
            return acc;
        }, new Set<string>())).filter(p => p !== 'all' && p !== 'cheapest');

        const { allProvidersCollection } = this.providersService;

        const providerItems = providers.map(p => ({
            name: allProvidersCollection?.find(providerData => providerData.name === p)?.label || p,
            value: p,
        }));

        this.updateOptions({ providers: providerItems });

        // Providers will be reseted only if no providers in the store or some of selected providers not in the hotel's compsets
        if (!this.settings.providers
            || !this.settings.providers.length
            || !this.settings.providers.every(p => providers.includes(p))
        ) {
            this.updateSettings({ providers });
        }
    }
}
