import { Expose, Transform } from 'class-transformer';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesCheckinDayModel from '@/modules/rates/models/rates-checkin-day.model';
import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import { TrendsDocument, TrendEntityData } from '../types';
import { NUMBER_OF_CHART_DAYS } from '../constants';

const collectProvidersFromRooms = (rooms: Record<string, RatesDocumentItemModel[]>) => {
    const roomTypeIds = Object.keys(rooms);
    const providers = [] as string[];

    roomTypeIds.forEach(t => {
        rooms[t].forEach(r => {
            if (r.provider) {
                providers.push(r.provider);
            }
        });
    });

    return providers;
};

/** Transform PlainDocument into trends initial document.
 * Only for hotel level rates price history.
 * @field trendData: list of trend items, 0 element is initial rates document
 * @field date: requested checkin date
 * @field documentId: id of initial rates doucment
 * @field names: key-value pairs of hotel names
 * @field exchangeRate: currency excange rate
 * @field providerName: name of initial rate document's provider
 * @field currency: document's currency
 */

export type PlainDocument = RatesDocumentModel
& { extraRooms?: Record<string, RatesDocumentItemModel>[] }
& { date: Date, names: Record<string, string>, entities?: string[] };

export class RatesDocToTrendsModel implements TrendsDocument {
    @Expose()
    @Transform((_, plain: PlainDocument) => {
        const { checkinDates, extraRooms } = plain;

        if (!checkinDates || !checkinDates[plain.date.getDate()]) {
            return null;
        }

        const checkinDate = checkinDates[plain.date.getDate()] as RatesCheckinDayModel;
        const hotelIds = (plain.entities || Object.keys(checkinDate.hotels || {})) as unknown as number[];

        const data = hotelIds.reduce((acc, hotelId) => {
            // If no main room, but there is any compared rooms
            if (!checkinDate.hotels[hotelId]) {
                const resultRooms = [null] as (RatesDocumentItemModel | null)[];

                if (extraRooms?.length) {
                    extraRooms.forEach(roomsGroup => {
                        if (roomsGroup && roomsGroup[hotelId]) {
                            resultRooms.push(roomsGroup[hotelId]);
                        }
                    });
                }

                return {
                    ...acc,
                    [String(hotelId)]: {
                        rooms: resultRooms,
                        entityType: 'hotel',
                    } as TrendEntityData,
                };
            }

            const { screenshot, losRestriction, link } = checkinDate.hotels[hotelId];
            const {
                updateDate, rooms, pax, taskId,
            } = checkinDate.hotels[hotelId];

            const providers = collectProvidersFromRooms(rooms);

            const roomTypeId = (rooms && Object.keys(rooms).length) ? Number(Object.keys(rooms)[0]) : null;
            const room = roomTypeId !== null ? rooms[roomTypeId][0] : null;
            const resultRooms = room ? [room] : [];

            if (extraRooms?.length) {
                extraRooms.forEach(roomsGroup => {
                    if (roomsGroup && roomsGroup[hotelId]) {
                        resultRooms.push(roomsGroup[hotelId]);
                    }
                });
            }

            return {
                ...acc,
                [String(hotelId)]: {
                    rooms: resultRooms,
                    entityType: 'hotel',
                    updateDate,
                    link,
                    losRestriction,
                    screenshot,
                    pax,
                    providers,
                    taskId,
                } as TrendEntityData,
            };
        }, {} as { [hotelId: string]: TrendEntityData });

        return [{
            data,
            demand: checkinDate.demand,
            occupancy: checkinDate.occupancy,
            updateDate: checkinDate.updateDate,
        }].concat(Array(NUMBER_OF_CHART_DAYS - 1).fill(null));
    })
    trendData!: TrendsDocument['trendData'];

    @Expose()
    @Transform((_, plain: PlainDocument) => plain.date)
    date!: Date;

    @Expose()
    @Transform((_, plain: PlainDocument) => plain.id)
    documentId!: number;

    @Expose({ name: 'names' })
    names!: Record<string, string>;

    @Expose()
    @Transform((_, plain: PlainDocument) => plain.exchangeRate)
    exchangeRate!: number;

    @Expose()
    @Transform((_, plain: PlainDocument) => plain.providerName)
    providerName!: string;

    @Expose()
    @Transform((_, plain: PlainDocument) => plain.currency)
    currency?: string;

    @Expose()
    @Transform((_, plain: PlainDocument) => plain.scan.id || undefined)
    scanId?: string;
}
