import moment from 'moment';

import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';

import type { ProviderData } from '@/modules/di-lite/interfaces/provider-data.interface';
import type Day from '@/modules/common/types/day.type';
import DiLiteStore from '@/modules/di-lite/store/di-lite.store';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import Price from '@/modules/common/types/price.type';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';

import type StoreFacade from '@/modules/common/services/store-facade';
import type HelperService from '@/modules/common/services/helper.service';
import RatesApiService, { RatesApiServiceS, RatesRequestMode } from '@/modules/rates/rates-api.service';
import type CompsetsService from '@/modules/compsets/compsets.service';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import type DocumentFiltersService from '../document-filters/document-filters.service';
import type DiLiteMarketService from './di-lite-market.service';
import AllChannelsSettingsModel, { DILiteFilterDevice } from './models/all-channels-settings.model';
import RatesCommonService, { RatesCommonServiceS } from '../common/modules/rates/rates-common.service';
import { SettingsGeneralService } from '../settings/settings-general.service';

import PRICE from '../common/modules/rates/constants/price.enum';
import PRICE_SHOWN from '../rates/constants/price-shown.constant';
import ANY_MEAL_TYPE from '../meal-types/constants/any-meal-type.constant';
import ANY_ROOM_TYPE from '../room-types/constants/any-room-type.constant';
import type UserService from '../user/user.service';

export const DiLiteAllChannelsServiceS = Symbol.for('DiLiteAllChannelsServiceS');
@injectable()
export default class DiLiteAllChannelsService implements Stateable {
    @inject(KEY.DiLiteMarketService) private diLiteMarketService!: DiLiteMarketService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;
    @inject(RatesApiServiceS) private ratesApiService!: RatesApiService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(MealTypesServiceS) private mealTypeService!: MealTypesService;
    @inject(KEY.UserService) private userService!: UserService;

    readonly storeState: DiLiteStore = this.storeFacade.getState('DiLiteStore');

    readonly brand: string = 'bcom';

    private currentDocument!: RatesDocumentAllModel;

    constructor() {
        // NOTE Applying default filters
        this.storeFacade.watch(
            () => [
                this.settingsGeneralService.defaultFilters.price,
                this.settingsGeneralService.defaultFilters.numberOfGuests,
                this.settingsGeneralService.defaultFilters.mealType,
            ],
            (n, o) => {
                if (JSON.stringify(n) === JSON.stringify(o)) {
                    return;
                }

                const { defaultFilters } = this.settingsGeneralService;

                this.settings.priceType = defaultFilters.price;
                this.settings.numberOfGuests = defaultFilters.numberOfGuests;

                const defaultMealType = this.mealTypeService.getMealType(defaultFilters.mealType);
                this.settings.mealTypeId = defaultMealType ? defaultMealType.id : -1;
            },
            { immediate: true },
        );

        this.storeFacade.watch(
            () => this.mealTypeService.mealTypes,
            () => {
                const { mealTypes } = this.mealTypeService;
                const { defaultFilters } = this.settingsGeneralService;

                if (mealTypes.length <= 1 && this.settings.mealTypeId !== -1) { return; }

                const neededMealType = this.mealTypeService.getMealType(defaultFilters.mealType)!;
                this.settings.mealTypeId = neededMealType ? neededMealType.id : -1;
            },
        );

        this.storeFacade.watch(
            () => this.userService.user.currentHotelId,
            () => this.resetFilters(),
        );

        this.storeFacade.watch(() => [
            this.allProviders,
        ], ((newValue, oldValue) => {
            const [newCompset] = newValue;
            const [currentCompset] = oldValue;

            if (newCompset && JSON.stringify(currentCompset) !== JSON.stringify(newCompset)) {
                this.provider = null;
                this.diLiteMarketService.resetLoading();
                return this.resetLoading.call(this);
            }
            return {};
        }));

        this.storeFacade.watch(() => [
            this.documentFiltersService.storeState.settings.compsetId,
            this.settings.device,
        ], () => {
            this.shownProviders = [];
        });

        this.storeFacade.watch(() => [
            this.settingsGeneralService.displayCurrency,
            this.storeState.settings.mealTypeId,
            this.storeState.settings.roomTypeId,
            this.storeState.settings.priceType,
            this.storeState.settings.device,
            this.documentFiltersService.storeState.settings.los,
            this.documentFiltersService.storeState.settings.pos,
            this.documentFiltersService.storeState.settings.compsetId,
            this.storeState.settings.numberOfGuests,
            this.userService.user.currentHotelId,
            this.documentFiltersService.storeState.settings.month,
            this.documentFiltersService.storeState.settings.year,
        ], () => {
            this.resetLoading();
        });

        this.storeFacade.watch(() => [
            this.storeState.settings.mealTypeId,
            this.storeState.settings.roomTypeId,
            this.storeState.settings.priceType,
            this.storeState.settings.device,
            this.documentFiltersService.priceShown,
        ], () => {
            this.mapData(this.currentDocument);
        });
    }

    resetLoading() {
        this.storeState.pricesData = null;
        this.storeState.loading.reset();
    }

    async loadData(): Promise<boolean> {
        const documentSettings = this.documentFiltersService.settings;

        if (!this.compset) {
            return false;
        }

        if (!this.availableLoses.includes(documentSettings.los!)) {
            [documentSettings.los] = this.availableLoses;
            this.storeState.loading.start();
            return false;
        }

        this.currentDocument = await this.loadDocument(documentSettings) as RatesDocumentAllModel;

        this.provider = this.mainProvider;
        this.mapData(this.currentDocument);

        return true;
    }

    private loadDocument(documentSettings: DocumentFiltersModel | null) {
        if (!documentSettings) {
            return null;
        }
        const { displayCurrency } = this.settingsGeneralService;
        const unitedSettings = {
            ...documentSettings,
            ...this.settings,
            provider: 'all',
        };

        return this.ratesApiService
            .getRatesDocument(unitedSettings, displayCurrency, {}, RatesRequestMode.DILITE);
    }

    get availableLoses() {
        const { compset } = this;

        const losList = compset
            ? compset.los
            : [1];

        return losList;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.pricesData;
    }

    get isBrandAvailable() {
        return this.allProviders.includes(this.brand);
    }

    get compset() {
        return this.compsetsService.getCompset(this.filters.compsetId)
            || this.compsetsService.currentCompset || null;
    }

    get providers() {
        if (!this.allProviders.length) {
            return [];
        }

        const { device } = this.settings;

        const withoutCommonProviders = (provider: string) => !['all', 'cheapest'].includes(provider);
        const withoutBrand = (provider: string) => provider !== this.brand;
        const byDevice = (provider: string) => {
            switch (device) {
                case DILiteFilterDevice.MOBILE:
                    return provider.includes('mobile');

                case DILiteFilterDevice.DESKTOP:
                    return !provider.includes('mobile');

                default:
                    return true;
            }
        };

        let providers = this.allProviders
            .filter(withoutCommonProviders)
            .filter(withoutBrand);

        if (this.allProviders.includes('booking')) {
            providers = [...providers.filter((provider: string) => provider !== 'booking')];
            providers.unshift('booking');
        }

        return providers
            .filter(byDevice);
    }

    get shownProviders() {
        return this.settings.shownProviders;
    }

    set shownProviders(list: string[]) {
        this.settings.shownProviders = list;
    }

    get allProviders() {
        if (!this.compset) {
            return [];
        }

        return this.compset.rateProviders;
    }

    get mainProvider() {
        if (this.isBrandAvailable) {
            return this.brand;
        }
        if (this.providers.includes('booking')) {
            return 'booking';
        }
        return this.providers[0];
    }

    get provider() {
        return this.storeState.provider;
    }

    set provider(value: string | null) {
        this.storeState.provider = value;
    }

    get days() {
        return this.documentFiltersService.days;
    }

    get loading() {
        return this.storeState.loading;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get demandData() {
        return this.storeState.demandData;
    }

    get currency() {
        return this.storeState.currency;
    }

    get filters() {
        return this.documentFiltersService.settings;
    }

    get settings() {
        return this.storeState.settings;
    }

    getDateByDay(day: Day) {
        return moment(new Date(this.documentFiltersService.year, this.documentFiltersService.month)).add(day - 1, 'day')
            .format('DD-MM-YYYY');
    }

    isBasic(day: Day, provider: string, document: RatesDocumentAllModel): boolean {
        if (!document.checkinDates || !document.checkinDates[day] || !document.checkinDates[day]![provider]) {
            return false;
        }

        if (!provider.includes('basic')) {
            return false;
        }

        const { rooms } = document.checkinDates[day]![provider];
        const roomTypeId = parseInt(Object.keys(rooms)[0], 10);

        return rooms[roomTypeId][0].isBasic;
    }

    getPriceData(date: string, provider: string) {
        return (
            this.data
            && this.data[date]
            // Temporary solution because of bug with ctrip_api
            && this.data[date][provider.split('_api')[0]]
        ) || null;
    }

    getPrice(date: string, provider: string): number | null {
        const priceData = this.getPriceData(date, provider);
        return priceData ? priceData.price : null;
    }

    getReferencePrice(day: string) {
        if (this.isBrandAvailable) {
            return this.getPrice(day, this.brand);
        }

        const { provider } = this.storeState;
        if (!provider) return null;

        return this.getPrice(day, provider);
    }

    getMaxDiff(day: string): { diff: number; diffRatio: number; } | null {
        const maxDiff : { diff: number; diffRatio: number; } | null = null;

        const providersPrices = this.shownProviders
            .map((provider: string) => {
                const providerData = this.getPriceData(day, provider);
                return providerData ? providerData.price : null;
            })
            .filter(price => !!price);

        let { provider } = this.storeState;

        if (provider !== 'bcom' && !this.shownProviders.includes(provider!)) {
            [this.storeState.provider] = this.shownProviders;
            provider = this.storeState.provider;
        }

        const mainPrice = provider
            ? this.getPrice(day, provider)
            : null;

        if (!mainPrice) {
            return maxDiff;
        }

        if (mainPrice && !providersPrices.length) {
            return {
                diff: 0,
                diffRatio: 0,
            };
        }

        const diffs = providersPrices
            .map(price => {
                let diff = 0;
                let diffRatio = 0;
                if (mainPrice && price) {
                    const averagePrice = (price + mainPrice) / 2;
                    diff = price - mainPrice;
                    diffRatio = (diff / averagePrice) * 100;
                }
                return {
                    diff: Math.abs(diff),
                    diffRatio: Math.round(diffRatio),
                };
            });
        const sortedDiffs = diffs
            .sort((a, b) => Math.abs(b.diff) - Math.abs(a.diff));

        return sortedDiffs[0];
    }

    private async resetFilters() {
        const defaultMealType = this.mealTypeService.getMealType(this.settingsGeneralService.defaultFilters.mealType)?.id! || ANY_MEAL_TYPE.id;
        const { price: defaultPriceType, numberOfGuests: defaultNumberOfGuests } = this.settingsGeneralService.defaultFilters;

        this.storeState.settings = Object.assign(new AllChannelsSettingsModel(), {
            numberOfGuests: defaultNumberOfGuests,
            priceType: defaultPriceType,
            roomTypeId: ANY_ROOM_TYPE.id,
            mealTypeId: defaultMealType,
            device: DILiteFilterDevice.ALL,
            provider: this.providers.includes('booking')
                ? 'booking'
                : this.providers[0] || 'booking',
        });
    }

    private getDemandFromDocument(day: number, document: RatesDocumentAllModel) {
        if (document && document.demand && document.demand[day]) {
            return Number(document.demand[day]) * 100;
        }
        return null;
    }

    private getTableAssessment(price: Price | null, day: Day, document: RatesDocumentAllModel): ASSESSMENTS_TYPES.GOOD| ASSESSMENTS_TYPES.BAD | null {
        if (!this.compsetsService.currentCompset || !price || !this.provider) {
            return null;
        }

        const { type } = this.compsetsService.currentCompset;
        const mainPrice = this.getProviderPriceFromDocument(day, this.provider, document);

        if (mainPrice === null) {
            return null;
        }

        if (type === COMPSET_TYPE.HIGH || type === COMPSET_TYPE.MEDIAN) {
            return price > mainPrice ? ASSESSMENTS_TYPES.GOOD : ASSESSMENTS_TYPES.BAD;
        }

        return price <= mainPrice ? ASSESSMENTS_TYPES.GOOD : ASSESSMENTS_TYPES.BAD;
    }

    private mapData(document: RatesDocumentAllModel) {
        const providersData: {
            [date: string]: {
                [provider: string]: ProviderData
            }
        } = {};
        const demandData: (number | null)[] = [];

        this.storeState.currency = document && (document.currency || null);

        this.documentFiltersService.days.forEach(day => {
            const date = moment(day, 'DD-MM-YYYY');

            const demand = this.getDemandFromDocument(date.date(), document);
            demandData.push(demand);

            providersData[day] = {};

            const providers = this.isBrandAvailable
                ? [...this.providers, 'bcom']
                : this.providers;

            providers.forEach(provider => {
                // Temporary solution because of bug with ctrip_api
                providersData[day][provider.split('_api')[0]] = this.calculatePriceData(day, provider, document);
            });
        });

        this.storeState.pricesData = providersData;
        this.storeState.demandData = demandData;

        if (!this.shownProviders.length) {
            this.shownProviders = this.providers;
        } else {
            this.shownProviders = this.shownProviders
                .filter(provider => this.providers.includes(provider));
        }
    }

    private calculatePriceData(day: Day, provider: string, document: RatesDocumentAllModel) {
        const data: ProviderData = {
            price: null,
            isOutOfRange: false,
            isNoData: false,
            isNA: false,
            isSoldOut: false,
            isBasic: false,
            assessmentType: null,
            currency: null,
            losRestriction: false,
            isNetCalc: false,
            isTotalCalc: false,
            pax: null as number[] | null,
        };

        if (this.isOutOfRange(document)) {
            data.isOutOfRange = true;
            return data;
        }

        // Temporary solution because of bug with ctrip_api
        const actualProvider = provider.split('_api')[0];

        const providerData = document.checkinDates![day]?.[actualProvider];
        if (this.isNoData(day, document)) {
            data.isNoData = true;
            return data;
        }
        if (this.isNa(day, actualProvider, document)) {
            data.isNA = true;
            return data;
        }
        if (this.isSoldOut(day, actualProvider, document)) {
            data.isSoldOut = true;
            data.pax = providerData?.pax || null;
            return data;
        }

        data.price = this.getProviderPriceFromDocument(day, actualProvider, document);
        data.assessmentType = this.getTableAssessment(data.price, day, document);
        data.isBasic = this.isBasic(day, actualProvider, document);
        data.currency = this.currency;

        if (!providerData) return data;

        if (this.documentFiltersService.priceShown === PRICE_SHOWN.NET && providerData) {
            const { rooms } = providerData;
            data.isNetCalc = rooms[Number(Object.keys(rooms)[0])][0].isNetCalc;
        }

        if (this.documentFiltersService.priceShown === PRICE_SHOWN.TOTAL && providerData) {
            const { rooms } = providerData;
            data.isTotalCalc = rooms[Number(Object.keys(rooms)[0])][0].isTotalCalc;
        }

        data.losRestriction = providerData.losRestriction || false;

        return data;
    }

    private getProviderPriceFromDocument(day: Day, provider: string, document: RatesDocumentAllModel) {
        const data = this.checkinDates(day, document);

        if (!data[provider]) {
            return PRICE.NA;
        }

        const providerData = data[provider];

        const room = providerData
            ? Object.values(providerData.rooms || {})?.[0]?.[0] || null
            : null;

        if (!room) return PRICE.NA;

        return this.ratesCommonService.switchPrice(room);
    }

    private checkinDates(day: Day, document: RatesDocumentAllModel | null) {
        const checkinDate = document && document.checkinDates && document.checkinDates[day];
        if (!checkinDate) {
            return {};
        }
        return checkinDate;
    }

    private isNoData(day: Day, document: RatesDocumentAllModel | null) {
        if (!document || !document.checkinDates) {
            return true;
        }

        const allRooms = this.checkinDates(day, document);
        const isNoRooms = !Object.keys(allRooms).length;

        if (isNoRooms) {
            return true;
        }

        return !allRooms;
    }

    private isOutOfRange(document: RatesDocumentAllModel | null) {
        return !document;
    }

    private isSoldOut(day: Day, provider: string, document: RatesDocumentAllModel | null) {
        if (!document || !document.checkinDates) {
            return false;
        }

        const price = this.getProviderPriceFromDocument(day, provider, document);

        return !price;
    }

    private isNa(day: Day, provider: string, document: RatesDocumentAllModel | null) {
        if (!document) {
            return false;
        }

        return this.getProviderPriceFromDocument(day, provider, document) === PRICE.NA;
    }
}
