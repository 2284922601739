import {
    Expose, plainToClass, Transform,
    Type,
} from 'class-transformer';

import DefaultSettingsModel from '@/modules/user/models/default-settings.model';
import SpecialDateModel from '@/modules/user/models/special-date.model';
import { IntradaySettingsModel } from './intraday-settings.model';
import { CurrenciesSettingsModel } from './currencies-settings.model';
import { SubscriptionsSettingsModel } from './subscriptions-settings.model';
import type { AlertsAndNotifications, AlertsAndNotificationsGroups } from '../interfaces/alerts-and-notifications.interface';

type ColorHex = string;
type HotelId = number;

export interface CompsetReportsSettings {
    compsetId: string;
    provider: string;
    customizedRange: number;
}
export class SettingsModel {
    @Expose({ name: 'subscribedListForMail' })
    @Type(() => SubscriptionsSettingsModel)
    subscriptions!: SubscriptionsSettingsModel;

    @Expose()
    @Transform((_, settings) => plainToClass(CurrenciesSettingsModel, settings, { excludeExtraneousValues: true }))
    currencies!: CurrenciesSettingsModel;

    @Expose({ name: 'intraday' })
    @Type(() => IntradaySettingsModel)
    intraday!: IntradaySettingsModel;

    @Expose()
    alertsAndNotifications!: AlertsAndNotifications;

    @Expose()
    alertsAndNotificationsByGroup!: AlertsAndNotificationsGroups;

    @Expose()
    colors!: ColorHex[];

    @Expose({ name: 'defaultFilters' })
    @Type(() => DefaultSettingsModel)
    defaultFilters!: DefaultSettingsModel;

    @Expose({ name: 'intraday_special_dates' })
    @Transform(value => (Array.isArray(value) ? plainToClass(SpecialDateModel, value, { excludeExtraneousValues: true }) : []))
    intradaySpecialDates!: SpecialDateModel[];

    @Expose()
    @Transform((_, settings) => (typeof settings.lang !== 'string' ? 'en' : settings.lang))
    lang!: string;

    @Expose()
    emailHtmlReportSettings?: Record<HotelId, CompsetReportsSettings[]>;
}
